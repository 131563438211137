<template>
  <MainLayout
    isFullScreen
    class="main-layout--mail-validation">
    <img
      :src="heroImage"
      alt=""
      class="mail-validation__hero">
    <section class="mail-validation__wrapper">
      <header>
        <Heading class="mb-xs">
          {{ $t("artist.mailValidation.mailValidation") }}
        </Heading>
        <Paragraph>
          {{ $t("artist.mailValidation.emailSent") }}
        </Paragraph>
      </header>

      <div class="mail-validation__recap-wrapper">
        <div class="mail-validation__message">
          <Paragraph isTextCentered>
            {{ $t("artist.mailValidation.sentTo") }}
          </Paragraph>
          <Paragraph
            :color="'black'"
            isTextCentered
            isBold
            class="ml-xxs">
            {{ user.Email }}
          </Paragraph>
        </div>
        <div class="mail-validation__message">
          <Paragraph
            isTextCentered>
            {{ $t("artist.mailValidation.expirationMessage") }}
          </Paragraph>
          <Paragraph
            :color="'black'"
            isTextCentered
            isBold
            class="ml-xxs">
            {{ $t("artist.mailValidation.expirationDate") }}
          </Paragraph>
        </div>
      </div>

      <LegalLinks
        v-if="$mediaQueries.isDesktop"
        style="align-self: end;">
      </LegalLinks>
    </section>
  </MainLayout>
</template>

<script>

import { mapState }         from 'vuex';

import MainLayout           from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import LegalLinks           from '../../../components/molecules/LegalLinks/m-LegalLinks.vue';
import ArtistHeroImage      from '../../../../public/assets/images/artiste/email-validation.jpg';
import ClientHeroImage      from '../../../../public/assets/images/client/email-validation.jpg';

export default {
  name: 'p-MailValidation-Artist',
  components: {
    MainLayout,
    Heading,
    Paragraph,
    LegalLinks,
  },
  computed: {
    ...mapState('User', ['user']),
    isArtist() {
      return this.$route.path.includes('/artist');
    },
    heroImage() {
      return this.isArtist ? ArtistHeroImage : ClientHeroImage;
    },
  },
};

</script>

<style lang="scss">

.mail-validation {
  &__hero {
    grid-row: 1 / 2;
    object-fit: cover;
    max-height: 100%;
    min-width: 100%;

    @media screen and ($desktop) {
      position: fixed;
      left: 0;
      right: 0;
      min-width: 50%;
      max-width: 50%;
      min-height: 100%;
    }
  }

  &__wrapper {
    grid-column: 1 / 2;
    display: grid;
    grid-template-rows: 1fr auto;
    flex-direction: column;
    align-items: start;
    padding: var(--space-md);

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-template-rows: 1fr auto 1fr;
      max-width: 800px;
      align-content: center;
      align-items: center;
      padding: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
  }

  &__recap-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__message {
    display: flex;
    margin-bottom: var(--space-xs);
  }
}

</style>
